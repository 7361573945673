import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "../../templates/table/actions";
import {
  SET_ERROR as COMMIT_SET_ERROR,
  SET_ITEMS as COMMIT_SET_ITEMS,
  SET_LOADING as COMMIT_SET_LOADING
} from "../../templates/table/mutation-types";
import { cloneDeep } from "lodash";
import axios from "axios";

const fetchCall = api.articlesStats.fetchConversionsReport;
// const fetchCallQualityLabels =
//   api.articlesSubmissionsStats.fetchConversionsReportQualityLabels;

export default {
  ...tableActions,
  async [DISPATCH_FETCH]({ state, commit }) {
    // Clear error state, set load state.
    commit(COMMIT_SET_LOADING, true);
    commit(COMMIT_SET_ERROR, false);
    try {
      // Use cloneDeep on filters because some filter properties can be arrays.
      const filters = cloneDeep(state.filters);
      // Object assign is enough for pagination as it's properties can never be arrays.
      const pagination = Object.assign({}, state.pagination);
      // Make the provided api call.
      const { entities, result, meta } = await fetchCall({
        filters,
        pagination
      });
      // const data = await fetchCallQualityLabels({
      //   filters: { articles: result.toString() }
      // });
      // Object.values(data).forEach(item => {
      //   item.forEach(item => {
      //     entities.entity[item.id].qualityLabels = item.qualityLabels;
      //   });
      // });
      // Set items & clear load state on success
      commit(COMMIT_SET_ITEMS, { ids: result, entities, meta });
      commit(COMMIT_SET_LOADING, false);
      return result;
    } catch (e) {
      if (axios.isCancel(e)) {
        // do nothing
      } else {
        console.error("Failed to retrieve table module data:", e);
        // Clear load state and set error state on failure
        commit(COMMIT_SET_LOADING, false);
        commit(COMMIT_SET_ERROR, true);
        console.error(e);
      }
    }
  }
};
